import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./../data/constant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const EditBlog = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const quillRef = useRef();
  const navigate = useNavigate();
  const [imageUrl, setimageUrl] = useState("");

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`${baseUrl}/admin/get-blogs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.blog) {
        const { title, category, content, image } = data.blog;
        setTitle(title);
        setContent(content);
        setCategory(category);
        setimageUrl(image.url);
      }
    } catch (error) {
      console.error("Error fetching guides data:", error);
      toast.error("Failed to load guides. Please try again later.");
    }
  };

  const categories = ["Technical Analysis", "Technical Indicators", "Recent"];

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("category", category);
    formData.append("files", image);

    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${baseUrl}/admin/blog/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        toast.success("Blog Updated successfully!");
        navigate("/view-blogs");
        setTitle("");
        setContent("");
        setImage(null);
        setCategory("");
      }
    } catch (err) {
      toast.error("Error Updating blog");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-5">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-xl shadow-lg">
        <h2 className="text-3xl font-semibold text-center text-indigo-600 mb-6">
          Edit a New Blog
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="title" className="block text-lg font-medium mb-2">
              Blog Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter blog title"
              required
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          {/* Category Dropdown */}
          <div className="mb-4">
            <label
              htmlFor="category"
              className="block text-lg font-medium mb-2"
            >
              Category
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>
                Select a category
              </option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>
          {/* Content Editor (React Quill) */}
          <div className="mb-6">
            <label className="block text-lg font-medium mb-2">
              Blog Content
            </label>
            <ReactQuill
              ref={quillRef}
              value={content}
              onChange={setContent}
              placeholder="Write your blog here..."
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["bold", "italic", "underline", "strike"],
                  [{ align: [] }],
                  ["link", "image"],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ size: ["small", "medium", "large", "huge"] }],
                  ["blockquote"],
                ],
              }}
            />
          </div>
          {/* Image Upload */}
          <div className="mb-4">
            <label htmlFor="image" className="block text-lg font-medium mb-2">
              Display Image
            </label>
            <input
              id="image"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <div className="mt-3">
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-md cursor-pointer"
                />
              ) : imageUrl ? (
                <img
                  src={imageUrl}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-md cursor-pointer"
                />
              ) : (
                <p className="text-gray-500">No image selected</p>
              )}
            </div>
          </div>
          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              disabled={isLoading}
              className="px-6 py-3 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition"
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBlog;
