import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../data/constant";
import { useAuthContext } from "../contexts/AuthContext";
import moment from "moment";
import { useStateContext } from "../contexts/ContextProvider";
import Loader from "./Loader"; // Assuming you have a Loader component
import { FaFileCsv } from "react-icons/fa";
import { toast } from "react-toastify";

const ListUsers = () => {
  const { token } = useAuthContext();
  const { currentColor } = useStateContext();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userRole: "user",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const handleDownloadCSV = async () => {
    try {
      setCsvLoading(true);
      const response = await axios.get(`${baseUrl}/admin/users-csv`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users-list.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setCsvLoading(false);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      setCsvLoading(false);
    }
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setNewUser({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userRole: "user",
    });
    setIsAddModalOpen(false);
  };

  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddUser = async () => {
    setIsAddingUser(true); // Set loading state
    try {
      await axios.post(`${baseUrl}/admin/create-user`, newUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      closeAddModal();
      fetchUsers(); // Re-fetch the user list
    } catch (error) {
      console.error("Failed to add user:", error);
    } finally {
      setIsAddingUser(false); // Reset loading state
    }
  };

  const fetchUsers = async () => {
    setIsLoading(true); // Set loading state
    try {
      const { data } = await axios.get(`${baseUrl}/admin/get-all-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setUsers(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const openEditModal = (user) => {
    setSelectedUser({ ...user });
    setIsModalOpen(true);
  };

  const openConfirmModal = (user) => {
    setUserToDelete(user);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setUserToDelete(null);
    setIsConfirmModalOpen(false);
  };

  const confirmDeleteUser = async () => {
    if (!userToDelete) return;

    try {
      const { data } = await axios.delete(
        `${baseUrl}/admin/delete-user/${userToDelete._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("User Deleted Successfully");
        fetchUsers();
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
      toast.error("Failed to delete user. Please try again.");
    } finally {
      closeConfirmModal();
    }
  };


  const closeModal = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateUser = async () => {
    if (!selectedUser) return;

    setIsUpdatingUser(true); // Set loading state
    try {
      await axios.put(
        `${baseUrl}/admin/update-user/${selectedUser._id}`,
        selectedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      fetchUsers(); // Re-fetch the user list
    } catch (error) {
      console.error("Failed to update user:", error);
    } finally {
      setIsUpdatingUser(false); // Reset loading state
    }
  };

  return (
    <section className="text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Manage Users
          </h1>
          <div className="flex gap-4">
            <button
              onClick={handleDownloadCSV}
              style={{
                color: "white",
                background: currentColor,
                padding: "10px 20px",
                cursor: csvLoading ? "not-allowed" : "pointer",
                opacity: csvLoading ? 0.6 : 1,
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: "5px",
              }}
              disabled={csvLoading}
            >
              <FaFileCsv size={20} />
              {csvLoading ? "Downloading..." : "DOWNLOAD CSV"}
            </button>
            <button
              onClick={openAddModal}
              style={{
                color: "white",
                background: currentColor,
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: "5px",
              }}
            >
              Add New User
            </button>
          </div>
        </div>


        {isLoading ? (
          <Loader />
        ) : (
          <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-300">
            <table className="min-w-full table-auto bg-white border-collapse">
              <thead className="bg-gray-100 text-gray-700">
                <tr>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    First Name
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Last Name
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Email
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Role
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Date Created
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Verified
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user) => (
                  <tr key={user._id}>
                    <td className="px-6 py-4 border-b text-center">
                      {user.firstName}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user.lastName}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      {user.userRole}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {moment(user.createdAt).format("MM-DD-YYYY")}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user.isVerified ? "Yes" : "No"}
                    </td>
                    <td className="px-6 py-4 border-b text-center flex flex-col items-center gap-2">
                      <button
                        style={{ backgroundColor: currentColor }}
                        className="text-white px-4 py-2 rounded"
                        onClick={() => openEditModal(user)}
                      >
                        Edit
                      </button>
                      <button
                        style={{ backgroundColor: "red" }}
                        className="text-white px-4 py-2 rounded"
                        onClick={() => openConfirmModal(user)}
                      >
                        Delete
                      </button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Edit Modal */}
        {isModalOpen && selectedUser && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Manage User</h2>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={selectedUser.firstName}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={selectedUser.lastName}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={selectedUser.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Role</label>
                <select
                  name="userRole"
                  value={selectedUser.userRole}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Restrict / Un-Restrict User
                </label>
                <div className="flex items-center space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="isVerified"
                      value="true"
                      checked={selectedUser.isRestricted === true}
                      onChange={() =>
                        setSelectedUser((prev) => ({
                          ...prev,
                          isRestricted: true,
                        }))
                      }
                      className="w-5 h-5 mr-2"
                    />
                    Restrict
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="isVerified"
                      value="false"
                      checked={selectedUser.isRestricted === false}
                      onChange={() =>
                        setSelectedUser((prev) => ({
                          ...prev,
                          isRestricted: false,
                        }))
                      }
                      className="w-5 h-5 mr-2"
                    />
                    Un-Restrict
                  </label>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded"
                  onClick={handleUpdateUser}
                  disabled={isUpdatingUser} // Disable the button while updating
                >
                  {isUpdatingUser ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        )}

        {isAddModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Add New User</h2>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={newUser.firstName}
                  onChange={handleAddInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={newUser.lastName}
                  onChange={handleAddInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={newUser.email}
                  onChange={handleAddInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Create Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={newUser.password}
                  onChange={handleAddInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Role</label>
                <select
                  name="userRole"
                  value={newUser.userRole}
                  onChange={handleAddInputChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={closeAddModal}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded"
                  onClick={handleAddUser}
                  disabled={isAddingUser}
                >
                  {isAddingUser ? "Adding..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isConfirmModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-sm shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-gray-800">Confirm Deletion</h2>
            <p className="mb-4 text-gray-700">
              You are about to delete{" "}
              <strong className="text-gray-900">
                {userToDelete?.firstName} {userToDelete?.lastName}
              </strong>
              . <br />
              <span className="block mt-2">
                This action will <strong>cancel</strong> and <strong>remove</strong> all
                their active subscriptions permanently.
              </span>
            </p>
            <p className="mb-6 text-gray-700 font-medium">
              Are you sure you want to proceed?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition"
                onClick={closeConfirmModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                onClick={confirmDeleteUser}
              >
                Delete
              </button>
            </div>
          </div>

        </div>
      )}
    </section>
  );
};

export default ListUsers;
