import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    Card,
    CardContent,
    Divider,
    Box,
} from "@mui/material";
import { baseUrl } from "../data/constant";
import { useAuthContext } from "../contexts/AuthContext";
import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";
import { AttachMoney, CalendarMonth, CheckCircle, People } from "@mui/icons-material";
import Loader from "./Loader";
import { MdEdit } from 'react-icons/md';

const IndividualSubscriptions = () => {
    const { token } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [newPlanName, setNewPlanName] = useState("");
    const [newPlanDescription, setNewPlanDescription] = useState("");
    const [newPlanAmount, setNewPlanAmount] = useState("");
    const [newPlanCurrency, setNewPlanCurrency] = useState("usd");
    const [interval, setInterval] = useState("month");
    const [alertt, setAlert] = useState("");
    const [bulletPoints, setBulletPoints] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersForEdit, setSelectedUsersForEdit] = useState([]);
    const [users, setUsers] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [preSelectedUsers, setPreSelectedUsers] = useState([]);
    const [editDialog, setEditDialog] = useState(false);
    const { currentColor } = useStateContext();
    const [selectedSubscription, setSelectedSubscription] = useState(false);


    const fetchUsers = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/admin/get-all-users`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (data.success) {
                setUsers(data.data);
            }
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }
    };

    const fetchPastSubscriptions = async () => {
        try {
            setIsLoading(true);
            const { data } = await axios.get(`${baseUrl}/admin/individual-subscription`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (data.success) {
                setSubscriptions(data.subscriptions);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchPastSubscriptions();
    }, []);

    const handleUserSelection = (userId) => {
        setSelectedUsers((prevSelectedUsers) => {
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter((id) => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };
    const handleUserSelectionEdit = (userId) => {
        setSelectedUsersForEdit((prevSelectedUsers) => {
            if (prevSelectedUsers.includes(userId)) {
                return prevSelectedUsers.filter((id) => id !== userId);
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    const handleEditSubscription = async (type) => {
        console.log(selectedUsersForEdit)
        console.log(selectedSubscription)
        let payLoad = {}
        if (type == 'reAssign') {
            const mergedUsers = [...new Set([...selectedUsersForEdit, ...preSelectedUsers])]; // Merge and remove duplicates
            payLoad = {
                subscription: selectedSubscription,
                users: mergedUsers,
            };
        }
        else {
            payLoad = {
                subscription: selectedSubscription,
                users: selectedUsersForEdit,
            }
        }
        try {
            const response = await axios.put(
                `${baseUrl}/admin/individual-subscription`,
                payLoad,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.success) {
                setEditDialog(false);
                setSelectedUsersForEdit([]);
                setPreSelectedUsers([]);
                setSelectedSubscription(null);
                fetchPastSubscriptions();
            }
        } catch (error) {
            console.error("Failed to add plan:", error);
        }
    }

    const handleAddSubscription = async () => {
        if (!selectedUsers.length) {
            alert("Please select at lease one user");
            return;
        }
        try {
            const response = await axios.post(
                `${baseUrl}/admin/individual-subscription`,
                {
                    name: newPlanName,
                    amount: newPlanAmount,
                    currency: newPlanCurrency,
                    description: newPlanDescription,
                    interval,
                    metadata: {
                        interval,
                        alert: alertt,
                        bullet_points: bulletPoints,
                    },
                    users: selectedUsers,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.success) {
                setOpen(false);
                setNewPlanName("");
                setNewPlanDescription("");
                setNewPlanAmount("");
                setAlert("");
                setBulletPoints("");
                setSelectedUsers([]);
                fetchPastSubscriptions();
            }
        } catch (error) {
            console.error("Failed to add plan:", error);
        }
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: 3 }}>
                <h1
                    style={{
                        color: currentColor,
                    }}
                    className="ml-5 text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
                >
                    Past Individual Subscriptions
                </h1>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: currentColor,
                    }}
                    onClick={() => setOpen(true)}
                >
                    Add Individual Subscription
                </Button>
            </Grid>
            {isLoading ? (
                <Loader />
            ) : (
                <Grid container spacing={3} sx={{ padding: 2 }}>
                    {subscriptions?.map((sub) => (
                        <Grid item xs={12} sm={6} md={4} key={sub._id}>
                            <Card
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    borderRadius: 3,
                                    boxShadow: 4,
                                    transition: "transform 0.3s, box-shadow 0.3s",
                                    "&:hover": {
                                        transform: "scale(1.02)",
                                        boxShadow: 6,
                                    },
                                    backgroundColor: "#f9f9f9",
                                }}
                            >
                                <CardContent>
                                    {/* Subscription Title */}
                                    <Typography
                                        variant="h6"
                                        sx={{ color: "black", fontSize: "1.25rem", fontWeight: 600 }}
                                        gutterBottom
                                    >
                                        {sub.subcriptionDetails.product.name}
                                    </Typography>

                                    {/* Description */}
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "black", fontSize: "1rem", fontStyle: "italic", marginBottom: 2 }}
                                    >
                                        {sub.subcriptionDetails.product.description}
                                    </Typography>

                                    <Divider sx={{ marginY: 2 }} />

                                    {/* Amount */}
                                    <Box display="flex" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
                                        <AttachMoney color="success" />
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "black", fontSize: "1rem" }}
                                        >
                                            <strong>Amount:</strong> {sub.subcriptionDetails.plan.unit_amount / 100}{" "}
                                            {sub.subcriptionDetails.plan.currency.toUpperCase()}
                                        </Typography>
                                    </Box>

                                    {/* Interval */}
                                    <Box display="flex" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
                                        <CalendarMonth color="info" />
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "black", fontSize: "1rem" }}
                                        >
                                            <strong>Interval:</strong> {sub.subcriptionDetails.product.metadata.interval}
                                        </Typography>
                                    </Box>

                                    <Divider sx={{ marginY: 2 }} />

                                    {/* Features */}
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "black", fontSize: "1rem" }}
                                        gutterBottom
                                    >
                                        <strong>Features:</strong>
                                    </Typography>
                                    <Box component="ul" sx={{ pl: 2, mb: 2 }}>
                                        {sub.subcriptionDetails.product.metadata.bullet_points
                                            .split(", ")
                                            ?.map((feature, index) => (
                                                <Box
                                                    component="li"
                                                    key={index}
                                                    display="flex"
                                                    alignItems="center"
                                                    gap={1}
                                                >
                                                    <CheckCircle color="success" fontSize="small" />
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ color: "black", fontSize: "1rem" }}
                                                    >
                                                        {feature}
                                                    </Typography>
                                                </Box>
                                            ))}
                                    </Box>

                                    <Divider sx={{ marginY: 2 }} />

                                    {/* Users */}
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <People color="action" />
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "black", fontSize: "1rem" }}
                                        >
                                            <strong>Users:</strong>{" "}
                                            {sub.users.map((user) => `${user.firstName} ${user.lastName}`).join(", ")}
                                        </Typography>
                                    </Box>
                                    {/* Edit Button */}
                                    <Box sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            variant="contained"
                                            startIcon={<MdEdit />}
                                            sx={{
                                                color: "white",
                                                "&:hover": {
                                                    backgroundColor: "#64b5f6",
                                                },
                                            }}
                                            onClick={() => {
                                                setSelectedSubscription(sub)
                                                setEditDialog(true)
                                                setPreSelectedUsers(sub?.users?.map((user) => user._id) || []);
                                            }
                                            }
                                        >
                                            Edit
                                        </Button>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid >
            )}
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>Add New Individual Subscription</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Plan Name"
                        fullWidth
                        value={newPlanName}
                        onChange={(e) => setNewPlanName(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Plan Description"
                        fullWidth
                        value={newPlanDescription}
                        onChange={(e) => setNewPlanDescription(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Amount (in USD)"
                        fullWidth
                        type="number"
                        value={newPlanAmount}
                        onChange={(e) => setNewPlanAmount(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Currency</InputLabel>
                        <Select
                            value={newPlanCurrency}
                            onChange={(e) => setNewPlanCurrency(e.target.value)}
                            label="Currency"
                        >
                            <MenuItem value="usd">USD</MenuItem>
                            <MenuItem value="cad">CAD</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Interval</InputLabel>
                        <Select
                            value={interval}
                            onChange={(e) => setInterval(e.target.value)}
                            label="Interval"
                        >
                            <MenuItem value="month">Monthly</MenuItem>
                            <MenuItem value="year">Yearly</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Alert"
                        fullWidth
                        value={alertt}
                        onChange={(e) => setAlert(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        label="Bullet Points"
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter bullet points separated by commas, e.g., Feature 1, Feature 2"
                        value={bulletPoints}
                        onChange={(e) => setBulletPoints(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    <Typography variant="h6" gutterBottom>
                        Select Users
                    </Typography>
                    <Grid container spacing={2}>
                        {users?.map((user) => (
                            <Grid item xs={12} sm={6} key={user._id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedUsers.includes(user._id)}
                                            onChange={() => handleUserSelection(user._id)}
                                        />
                                    }
                                    label={user.firstName + " " + user.lastName}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAddSubscription}
                        color="primary"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : "Add Subscription"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editDialog} onClose={() => setEditDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>Assign Subscription</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {users?.map((user) => {
                            const isAssigned = preSelectedUsers.includes(user._id);
                            return (
                                <Grid item xs={12} sm={6} key={user._id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAssigned || selectedUsersForEdit.includes(user._id)}
                                                onChange={() => !isAssigned && handleUserSelectionEdit(user._id)}
                                                disabled={isAssigned}
                                            />
                                        }
                                        label={
                                            isAssigned
                                                ? `${user.firstName} ${user.lastName} (already assigned)` // Show "(already assigned)"
                                                : `${user.firstName} ${user.lastName}`
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleEditSubscription('reAssign')}
                        color="primary"
                        disabled={preSelectedUsers.length <= 0}
                    >
                        {isLoading ? <CircularProgress size={24} /> : "Re-Assign All"}
                    </Button>
                    <Button
                        onClick={() => handleEditSubscription('assign')}
                        color="primary"
                        disabled={selectedUsersForEdit.length <= 0}
                    >
                        {isLoading ? <CircularProgress size={24} /> : "Assign Selected"}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default IndividualSubscriptions;
