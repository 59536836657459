import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../data/constant";
import Loader from "./Loader";
import { useStateContext } from "../contexts/ContextProvider";

const ProductsPlans = () => {
  const [products, setProducts] = useState([]);
  const [newPlanBulletPoints, setNewPlanBulletPoints] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateOpen, setupdateOpen] = useState(false);
  const [newPlanName, setNewPlanName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [alert, setAlert] = useState("");
  const [newPlanAmount, setNewPlanAmount] = useState("");
  const [updatePlanName, setupdatePlanName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [updateAmount, setUpdateAmount] = useState("");
  const [updateCurrency, setUpdateCurrency] = useState();
  const [updateInterval, setUpdateInterval] = useState();
  const [updateBulletPoints, setUpdateBulletPoints] = useState("");
  const [updateAlerts, setUpdateAlerts] = useState("");
  const [newPlanCurrency, setNewPlanCurrency] = useState("");
  const token = localStorage.getItem("token");
  const { currentColor } = useStateContext();
  const [interval, setInterval] = useState("month");
  const [currentProduct, setCurrentProduct] = useState(null);
  const [sequenceId, setSequenceId] = useState(null);
  const [popular, setPopular] = useState(null);

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseUrl}/admin/list-subscriptions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.products) {
        setProducts(data.products);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProduct = async () => {
    setIsLoading(true);
    try {
      const payload = {
        name: updatePlanName,
        description: updateDescription,
        interval: updateInterval,
        amount: parseFloat(updateAmount),
        currency: updateCurrency,
        metadata: {
          bullet_points: updateBulletPoints,
          interval: updateInterval,
          alert: updateAlerts,
          sequenceId,
          popular,
        }
      }

      const response = await axios.put(
        `${baseUrl}/admin/update-subscription/${currentProduct.product.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchProducts();

    } catch (error) {
      console.error("Failed to update product:", error);
    } finally {
      setIsLoading(false);
      setupdateOpen(false);
    }
  };

  const addPlan = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/admin/create-subscription`,
        {
          name: newPlanName,
          amount: newPlanAmount,
          currency: newPlanCurrency,
          description: newDescription,
          interval,
          metadata: {
            interval,
            alert,
            bullet_points: newPlanBulletPoints,
            sequenceId: products?.length + 1
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        fetchProducts();
        setOpen(false);
        setNewPlanName("");
        setNewPlanName("");
        setNewDescription("");
        setNewPlanCurrency("");
      }
    } catch (error) {
      console.error("Failed to add plan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (amount, currency) => {
    return `${(amount / 100).toFixed(2)} ${currency.toUpperCase()}`;
  };

  const colorCombos = [{ backgroundColor: "#f5f5f5", textColor: "#212121" }];

  const getRandomColorCombo = () => {
    const randomIndex = Math.floor(Math.random() * colorCombos.length);
    return colorCombos[randomIndex];
  };

  return (
    <>
      <h1
        style={{
          color: currentColor,
        }}
        className="ml-5 text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
      >
        Subscriptions
      </h1>

      {/* Add Plan Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{ marginBottom: 3, float: "right" }}
      >
        Add Plan
      </Button>

      {/* Modal for Adding Plan */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add a New Plan</DialogTitle>
        <DialogContent sx={{ paddingRight: 3 }}>
          <TextField
            label="Plan Name"
            fullWidth
            value={newPlanName}
            onChange={(e) => setNewPlanName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Plan Description"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Amount (in USD)"
            fullWidth
            type="number"
            value={newPlanAmount}
            onChange={(e) => setNewPlanAmount(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={newPlanCurrency}
              onChange={(e) => setNewPlanCurrency(e.target.value)}
              label="Currency"
            >
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Interval</InputLabel>
            <Select
              value={interval}
              onChange={handleIntervalChange}
              label="Interval"
            >
              <MenuItem value="month">Monthly</MenuItem>
              <MenuItem value="year">Yearly</MenuItem>
            </Select>
          </FormControl>



          {/* alerts */}
          <TextField
            label="Alert"
            fullWidth
            type="number"
            value={alert}
            onChange={(e) => setAlert(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Bullet Points"
            fullWidth
            placeholder="Give Point , separated like this Give access to copy trading , 24/7 support and active update"
            value={newPlanBulletPoints}
            onChange={(e) => setNewPlanBulletPoints(e.target.value)}
            sx={{ marginBottom: 2 }}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={addPlan} color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Add Plan"}
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ flexGrow: 1, marginTop: 2, padding: 5 }}>
          <Grid container spacing={3}>
            {products
              .filter(
                (productData) =>
                  productData.product.metadata &&
                  productData.product.metadata.type !== "individual" &&
                  productData.product.metadata.isDeleted != "true"
              ).sort((a, b) => {
                const seqA = a.product.metadata.sequenceId;
                const seqB = b.product.metadata.sequenceId;
                return seqA - seqB;
              })
              .map((productData) => {
                const { backgroundColor, textColor } = getRandomColorCombo();

                return (
                  <Grid item xs={12} sm={6} md={4} key={productData.product.id}>
                    <Card
                      sx={{
                        boxShadow: 12,
                        borderRadius: 3,
                        backgroundColor: backgroundColor,
                        color: textColor,
                        padding: 3,
                        transition: "0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: 16,
                        },
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="div"
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                          }}
                        >
                          {productData.product.name || "Product Name"}
                        </Typography>

                        {/* Display Bullet Points with Circle Style */}
                        {productData.product.metadata &&
                          productData.product.metadata.bullet_points && (
                            <Box sx={{ marginBottom: 2 }}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold", marginBottom: 1 }}
                              >
                                Features:
                              </Typography>
                              <ul
                                style={{
                                  listStyleType: "circle",
                                  paddingLeft: "20px",
                                }}
                              >
                                {productData.product.metadata.bullet_points
                                  .split(",")
                                  .map((point, index) => (
                                    <li key={index} style={{ marginBottom: "5px" }}>
                                      {point.trim()}
                                    </li>
                                  ))}
                              </ul>
                            </Box>
                          )}

                        <Box>
                          {productData.plans
                            .filter((plan) => plan.active)
                            .map((plan) => (
                              <Box
                                key={plan.id}
                                sx={{
                                  marginBottom: 3,
                                  padding: 2,
                                  border: "1px solid #ddd",
                                  borderRadius: 2,
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <Typography variant="h6" component="div" gutterBottom>
                                  {formatPrice(plan.unit_amount, plan.currency)}
                                </Typography>
                                <Typography variant="body1" color="text.secondary" paragraph>
                                  Interval: {plan.recurring.interval.toUpperCase()} (
                                  {plan.recurring.interval_count} {plan.recurring.interval}s)
                                </Typography>

                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setupdateOpen(true);
                                    setupdatePlanName(productData?.product?.name);
                                    setUpdateDescription(productData?.product?.description);
                                    setUpdateAmount(plan.unit_amount / 100);
                                    setUpdateCurrency(plan.currency);
                                    setUpdateInterval(plan.recurring.interval);
                                    setUpdateBulletPoints(productData?.product?.metadata.bullet_points);
                                    setUpdateAlerts(productData?.product?.metadata.alert);
                                    setCurrentProduct(productData);
                                    setSequenceId(productData?.product?.metadata.sequenceId)
                                    setPopular(productData?.product?.metadata.popular)
                                  }}
                                  sx={{
                                    width: "100%",
                                    marginTop: 2,
                                    backgroundColor: currentColor
                                  }}
                                >
                                  Update
                                </Button>
                              </Box>
                            ))}

                          {/* {productData.plans.filter((plan) => !plan.active).length > 0 && (
                            <Box sx={{ marginTop: 5 }}>
                              <Typography
                                variant="h6"
                                component="div"
                                gutterBottom
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "1.2rem",
                                }}
                              >
                                Past Prices
                              </Typography>
                              {productData.plans
                                .filter((plan) => !plan.active)
                                .map((plan) => (
                                  <Box
                                    key={plan.id}
                                    sx={{
                                      marginBottom: 3,
                                      padding: 2,
                                      border: "1px solid #ddd",
                                      borderRadius: 2,
                                      backgroundColor: "#f9f9f9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    <Typography variant="h6" component="div" gutterBottom>
                                      {formatPrice(plan.unit_amount, plan.currency)}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" paragraph>
                                      Interval: {plan.recurring.interval.toUpperCase()} (
                                      {plan.recurring.interval_count} {plan.recurring.interval}s)
                                    </Typography>
                                  </Box>
                                ))}
                            </Box>
                          )} */}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}

          </Grid>
        </Box >
      )}
      <Dialog open={updateOpen} onClose={() => setupdateOpen(false)}>
        <DialogTitle>Update Subscription</DialogTitle>
        <DialogContent>
          <TextField
            label="Plan Name"
            fullWidth
            value={updatePlanName}
            onChange={(e) => setupdatePlanName(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Description"
            fullWidth
            value={updateDescription}
            onChange={(e) => setUpdateDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Amount"
            fullWidth
            type="number"
            value={updateAmount}
            onChange={(e) => setUpdateAmount(parseFloat(e.target.value))}
            sx={{ mb: 2 }}
          />

          <TextField
            label="Alerts"
            fullWidth
            type="number"
            value={updateAlerts}
            onChange={(e) => setUpdateAlerts(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel>Currency</InputLabel>
            <Select
              value={updateCurrency}
              onChange={(e) => setUpdateCurrency(e.target.value)}
              sx={{ mb: 2 }}
            >
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Interval</InputLabel>
            <Select
              value={updateInterval}
              onChange={(e) => setUpdateInterval(e.target.value)}
              sx={{ mb: 2 }}
            >
              <MenuItem value="month">Monthly</MenuItem>
              <MenuItem value="year">Yearly</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Metadata (Bullet Points)"
            fullWidth
            value={updateBulletPoints}
            onChange={(e) => setUpdateBulletPoints(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setupdateOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={updateProduct}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Update"}
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductsPlans;
